import React from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Tabs, Tab, Badge, Breadcrumb } from "react-bootstrap";
import {
  useGetBookingsOfCompanyEmployeeQuery,
  useGetBookingsOfSingleMemberQuery,
  useGetMeetingRoomPackageQuery,
  useGetMeetingRoomQuery,
  useGetMemberPurchasesQuery,
  useGetPackageDetailsForMemberQuery,
  useGetPackageQuery,
  useGetSingleCompanyQuery,
  useUpdateCompanyMutation,
} from "../../Slices/spaceApi";

import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import UpdateCompanyDetails from "./UpdateCompanyDetails";
import Loader from "../Loader/Loader";

import CompanyAndUsers from "./CompanyAndUsers";
import AddPackageForMember from "./AddPackageForMember";
import { useEffect } from "react";
import MemberPackageTable from "./MemberPackageTable";

import MemberInvoiceList from "./MemberInvoiceList";
import { requiredAlert } from "../../utils/Alerts";
import toast from "react-hot-toast";
import { convertUTCTimestampToLocal } from "../../utils/convertUTCTimestampToLocal";
import { formateDate } from "../../utils/formateDate";
import { useRef } from "react";
import { useGlobalContext } from "../../Context/GlobalContext";
import UserForCompany from "./UserForCompany";
import AddDeposit from "./AddDeposit";
import MemberPurchase from "./MemberPurchase";
import { useGetSpaceTypesQuery } from "../../Slices/constantApi";
import MemberAccessGroup from "./MemberAccessGroups";

const DetailedCompanyPage = () => {
  const { id } = useParams();
  const { billingOption } = useGlobalContext();
  const { setDashBoardTitle, setBreadcrumbs } = useGlobalContext();

  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { data: companyData, isLoading: loading } = useGetSingleCompanyQuery({
    spaceId: spaceId || currentSpaceId,
    companyId: id,
  });

  const { data: SpaceTypes, isLoading: spaceTypeloading } =
    useGetSpaceTypesQuery();

  const { isLoading: spacePackageLoading, data: FetchPackageData } =
    useGetPackageQuery(spaceId || currentSpaceId);
  const { isLoading: meetingPackageLoading, data: FetchMeetingPackageData } =
    useGetMeetingRoomPackageQuery(spaceId || currentSpaceId);

  const {
    isLoading: assignedPackageLoading,
    data: FetchMemberAssignedPackage,
  } = useGetPackageDetailsForMemberQuery({
    spaceId: spaceId || currentSpaceId,
    memberId: id,
  });
  const { isLoading: resourceLoading, data: resources } =
    useGetMeetingRoomQuery({
      id: spaceId || currentSpaceId,
      room_type: 1,
    });
  const { isLoading: bookingsLoading, data: FetchBookings } =
    useGetBookingsOfSingleMemberQuery({
      spaceId: spaceId || currentSpaceId,
      memberId: id,
    });

  const { isLoading: employeeBookingsLoading, data: FetchEmployeeBookings } =
    useGetBookingsOfCompanyEmployeeQuery({
      spaceId: spaceId || currentSpaceId,
      companyId: id,
    });
  const { isLoading: purchasesLoading, data: FetchPurchases } =
    useGetMemberPurchasesQuery({
      spaceId: spaceId || currentSpaceId,
      memberId: id,
    });

  useEffect(() => {
    setDashBoardTitle("Company Details");
  }, []);
  const [updateSpaceCompany, resultStats] = useUpdateCompanyMutation();
  const [apiSpinner, setApiSpinner] = useState(false);
  const [dailyPackages, setDailyPackages] = useState([]);
  const [weeklyPackages, setWeeklyPackages] = useState([]);
  const [totalRemainingHours, setTotalRemainingHours] = useState(0);
  const [hideFromPortal, setHideFromPortal] = useState(
    companyData?.hideFromPortal
  );
  const [portalAccess, setPortalAccess] = useState(companyData?.portalAccess);
  const [communityAccess, setCommunityAccess] = useState(
    companyData?.allow_community_posting
  );

  const [monthlyPackages, setMonthlyPackages] = useState([]);
  const [search, setSearch] = useState("");
  useEffect(() => {
    setPortalAccess(companyData?.portalAccess);
    setHideFromPortal(companyData?.hideFromPortal);
    setCommunityAccess(companyData?.allow_community_posting);
  }, [companyData]);
  useEffect(() => {
    if (FetchMemberAssignedPackage?.assignedPackages) {
      const monthlyData = FetchMemberAssignedPackage?.assignedPackages?.filter(
        (item) => item.expiry_duration == 3
      );
      const weeklyData = FetchMemberAssignedPackage?.assignedPackages?.filter(
        (item) => item.expiry_duration == 2
      );
      const dailyData = FetchMemberAssignedPackage?.assignedPackages?.filter(
        (item) => item.expiry_duration == 1
      );
      setDailyPackages(dailyData);
      setWeeklyPackages(weeklyData);
      setMonthlyPackages(monthlyData);
    }
  }, [FetchMemberAssignedPackage]);

  function timeStampToTime(timeStamp) {
    let timeMili = timeStamp * 1000;
    let date = new Date(+timeMili);
    date = date.toLocaleTimeString();
    let split = date.split(":");
    let set = split[2].split(" ");
    let convert = split[0] + ":" + split[1] + " " + set[1];
    return convert;
  }
  // function timeStampToDate(timeStamp) {
  //   let timeMili = timeStamp * 1000;
  //   let date = new Date(+timeMili);

  //   const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  //   let formattedDate = date.toLocaleDateString(undefined, options);

  //   return formattedDate;
  // }
  function timeStampToDate(timeStamp) {
    let timeMili = timeStamp * 1000;
    let date = new Date(+timeMili);

    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formatter = new Intl.DateTimeFormat("en-GB", options);

    let formattedDate = formatter.format(date);

    return formattedDate;
  }
  const [expiry, setExpiry] = useState(
    {
      label: "Select All",
      value: 0,
    },
    {
      label: "Monthly",
      value: 1,
    },
    {
      label: "Weekly",
      value: 2,
    },
    {
      label: "Daily",
      value: 3,
    }
  );

  const leftDivContent = Array?.from({ length: 30 }).map((_, index) => (
    <p key={index}>Left Content {index + 1}</p>
  ));

  const rightTabs = [
    "Overview",
    "Members",
    "Packages",
    "Purchases",
    "Bookings",
    "Invoices",
    "Security Deposits",
    "Access Groups",
  ];

  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);

    const tabContent = document.getElementById(`tab${index + 1}`);
    if (tabContent) {
      tabContent.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleHideFromPortal = () => {
    setApiSpinner(true);
    setHideFromPortal((prevHideFromPortal) => {
      const newHideFromPortal = !prevHideFromPortal;
      updateSpaceCompany({
        spaceId: spaceId || currentSpaceId,
        companyId: id,
        updateData: { hideFromPortal: newHideFromPortal },
      });
      return newHideFromPortal;
    });
  };

  const handlePortalAccess = () => {
    setApiSpinner(true);
    setPortalAccess((prevPortalAccess) => {
      const newPortalAccess = !prevPortalAccess;
      updateSpaceCompany({
        spaceId: spaceId || currentSpaceId,
        companyId: id,
        updateData: { portalAccess: newPortalAccess },
      });
      return newPortalAccess;
    });
  };

  const handleCommunityPostingAccess = () => {
    setApiSpinner(true);
    setCommunityAccess((preCommunityAccess) => {
      const newPortalAccess = !preCommunityAccess;
      updateSpaceCompany({
        spaceId: spaceId || currentSpaceId,
        companyId: id,
        updateData: { allow_community_posting: newPortalAccess },
      });
      return newPortalAccess;
    });
  };

  const filteredItems = FetchBookings?.bookingss
    ?.slice()
    .reverse()
    ?.filter((val) => {
      if (search == "") {
        return val;
      } else if (
        val.meetingRoom?.title.toLowerCase().includes(search.toLowerCase())
      ) {
        return val;
      }
    });
  const [search1, setSearch1] = useState("");

  const filteredItems1 = FetchEmployeeBookings?.bookingss?.filter((val) => {
    if (search1 == "") {
      return val;
    } else if (
      val.meetingRoom?.title.toLowerCase().includes(search1.toLowerCase())
    ) {
      return val;
    }
  });

  useEffect(() => {
    if (resultStats?.error) {
      setApiSpinner(false);

      requiredAlert(resultStats?.error?.data?.message || "An Error Occured");
    }

    if (resultStats?.isSuccess) {
      setApiSpinner(false);

      toast.success("Updated Successfully!", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  }, [resultStats]);

  const [packageEventKey, setPackageEventKey] = useState("");
  const [isAnyPackage, setIsAnyPackage] = useState(false);
  useEffect(() => {
    if (
      monthlyPackages?.length > 0 ||
      weeklyPackages?.length > 0 ||
      dailyPackages?.length > 0
    ) {
      setIsAnyPackage(true);
      if (monthlyPackages?.length > 0) {
        setPackageEventKey(
          monthlyPackages?.some((item) => item.status == 1)
            ? "active"
            : monthlyPackages?.some((item) => item.status == 2)
            ? "pending"
            : "history"
        );
        return;
      } else if (weeklyPackages?.length > 0) {
        setPackageEventKey(
          weeklyPackages?.some((item) => item.status == 1)
            ? "active"
            : weeklyPackages?.some((item) => item.status == 2)
            ? "pending"
            : "history"
        );
        return;
      } else if (dailyPackages?.length > 0) {
        setPackageEventKey(
          dailyPackages?.some((item) => item.status == 1)
            ? "active"
            : dailyPackages?.some((item) => item.status == 2)
            ? "pending"
            : "history"
        );
        return;
      }
    }
  }, [monthlyPackages, weeklyPackages, dailyPackages]);
  useEffect(() => {
    const allPackages = [
      ...monthlyPackages,
      ...weeklyPackages,
      ...dailyPackages,
    ];

    const filteredPackages = allPackages.filter(
      (pkg) => pkg.status === 1 && pkg.remaining_hours.length > 0
    );

    const totalHoursSum = filteredPackages.reduce(
      (sum, pkg) => sum + pkg.remaining_hours[0].hours,
      0
    );

    setTotalRemainingHours(totalHoursSum);
  }, [monthlyPackages, weeklyPackages, dailyPackages]);
  if (
    loading ||
    spacePackageLoading ||
    meetingPackageLoading ||
    assignedPackageLoading ||
    bookingsLoading ||
    purchasesLoading
  ) {
    return <Loader height={6.6} />;
  }

  return (
    <div className="container-fluid" style={{ padding: "0px 28px" }}>
      <Breadcrumb className="custom-breadcrumb ml-auto">
        <Breadcrumb.Item>
          <Link to="/member/list">Members List</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{companyData?.name}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="row">
        <div
          className="card col-12 col-lg-3 small-div mt-2"
          style={leftDivStyle}
        >
          <div className="mt-2 p-3">
            <div className="d-flex justify-content-between">
              <h4
              // className="float-left"
              >
                Company
              </h4>
              <h5
              // className="float-right"
              >
                {" "}
                <UpdateCompanyDetails
                  companyId={id}
                  companyName={companyData?.name}
                  companyEmail={companyData?.email}
                  profileImg={companyData?.profileImg}
                  address={companyData?.address}
                  phoneNo={companyData?.phoneNo}
                  status={companyData?.status}
                  // securityDeposit={companyData?.securityDeposit}
                  contactPerson={companyData?.contactPerson}
                />
              </h5>
            </div>
            <div className="text-center mt-2">
              <img
                src={
                  companyData?.profileImg
                    ? companyData.profileImg
                    : "https://placehold.jp/90x90.png"
                }
                style={{
                  width: "90px",
                  height: "90px",
                  objectFit: "cover",
                }}
                className="rounded mt-2"
              />
            </div>
            <div className="media-body my-auto d-flex flex-column mt-4">
              <span className="mb-0 table-avatar">
                <b>{companyData?.name}</b>
              </span>
              <small className="mb-0 text-muted">{companyData?.email}</small>
            </div>
            <hr />
            <div className="general d-flex flex-column">
              {/* <div className="">
                <h4 className="float-left">General</h4>
              </div> */}

              {companyData?.phoneNo && (
                <div className="">
                  <h6 className="float-left">Phone No:</h6>
                  <p className="float-right">{companyData?.phoneNo}</p>
                </div>
              )}
              {companyData?.cnicNo && (
                <div className="">
                  <h6 className="float-left">ID Card Number: </h6>
                  <p className="float-right">{companyData?.cnicNo}</p>
                </div>
              )}
              {companyData?.address && (
                <div className="">
                  <h6 className="float-left">Address: </h6>
                  <p className="float-right">{companyData?.address}</p>
                </div>
              )}
              {companyData?.description && (
                <div className="">
                  <h6 className="float-left">About: </h6>
                  <p className="float-right">{companyData?.description}</p>
                </div>
              )}

              <hr />
            </div>
            {/* <div className="memberApp">
              <h4>Member Apps</h4> */}
            {/* <div className="">
                <small className="float-left">HIDE FROM MEMBER PORTAL</small>
                <Form>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label=""
                    className="float-right"
                    // value={hideFromPortal}
                    checked={hideFromPortal}
                    onChange={handleHideFromPortal}
                    disabled={apiSpinner}
                  />
                </Form>
              </div> */}
            {/* </div> */}

            {/* <div className="">
              <div className="">
                <small className="float-left">MEMBER PORTAL ACCESS</small>
                <Form>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label=""
                    className="float-right"
                    // value={portalAccess}
                    checked={portalAccess}
                    onChange={handlePortalAccess}
                    disabled={apiSpinner}
                  />
                </Form>
              </div> */}
          </div>
          <h4>Member Apps</h4>

          <div className="row">
            <div className="d-flex align-items-center justify-content-between">
              <small>HIDE FROM MEMBER PORTAL</small>
              <Form>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=""
                  // value={hideFromPortal}
                  checked={hideFromPortal}
                  onChange={handleHideFromPortal}
                  disabled={apiSpinner}
                />
              </Form>
            </div>
          </div>
          <div className="row">
            <div className="d-flex align-items-center justify-content-between">
              <small>MEMBER PORTAL ACCESS</small>
              <Form>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=""
                  className=""
                  // value={portalAccess}
                  checked={portalAccess}
                  onChange={handlePortalAccess}
                  disabled={apiSpinner}
                />
              </Form>
            </div>
            {/* <div className="row">
              <div className=" ">
                <small className="">COMMUNITY POSTING ACCESS</small>
                <Form>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label=""
                    className=""
                    // value={portalAccess}
                    checked={communityAccess}
                    onChange={handleCommunityPostingAccess}
                    disabled={apiSpinner}
                  />
                </Form>
              </div>
            </div> */}
          </div>
          <div className="row">
            <div className="d-flex align-items-center justify-content-between">
              <small className="">COMMUNITY POSTING ACCESS</small>
              <Form>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=""
                  className=""
                  checked={communityAccess}
                  onChange={handleCommunityPostingAccess}
                  disabled={apiSpinner}
                />
              </Form>
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-9">
          <ul
            className="nav  nav-underline"
            variant="underline"
            justify
            style={{ cursor: "pointer", overflow: "auto" }}
          >
            {rightTabs?.map((tab, index) => (
              <li className="nav-item" key={index}>
                <a
                  className={`nav-link ${
                    index === activeTab ? "active" : ""
                  } ml-2`}
                  onClick={() => handleTabClick(index)}
                >
                  {tab}
                </a>
              </li>
            ))}
          </ul>

          <div className="tab-content mt-3" style={rightDivStyle}>
            <div
              className={` ${activeTab == 0 ? "active" : ""}`}
              id={`tab${1}`}
              onMouseEnter={() => setActiveTab(0)}

              // key={index}
            >
              <div className="card mt-3 p-4">
                <div className="justify-content-around d-flex">
                  <div>
                    <small className="p-4">{companyData?.memberCount}</small>
                    <p className="mt-2">Members</p>
                  </div>

                  <div>
                    <small className="p-4">
                      {companyData?.activePackageCount}
                    </small>
                    <p className="mt-2">Packages</p>
                  </div>
                  <div>
                    <small className="p-4">
                      {" "}
                      {companyData?.upcommingBookingCount || 0}
                    </small>
                    <p className="mt-2">Bookings</p>
                  </div>
                  <div>
                    <small className="p-4">{companyData?.invoiceCount}</small>
                    <p className="mt-2">Invoices</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={` ${activeTab == 1 ? "active" : ""}`}
              id={`tab${2}`}
              onMouseEnter={() => setActiveTab(1)}

              // key={index}
            >
              <CompanyAndUsers id={id} companyData={companyData} />
            </div>
            
            <div
              className={` ${activeTab == 2 ? "active" : ""}`}
              id={`tab${3}`}
              onMouseEnter={() => setActiveTab(2)}
            >
              <div className="card">
                <div className="mt-3 d-flex justify-content-between">
                  <div className="d-flex">
                    <h4 className="mt-2 ml-4">Packages</h4>
                    <AddPackageForMember
                      id={id}
                      FetchPackageData={FetchPackageData}
                      FetchMeetingPackageData={FetchMeetingPackageData}
                      memberName={companyData?.name}
                      SpaceTypes={SpaceTypes}
                      Resources={resources?.meetingRooms}
                    />
                  </div>
                  <p
                    className="float-right mr-2"
                    title={`Remaining ${
                      billingOption == 1 ? "Hours" : "Coins"
                    } for Bookings`}
                  >
                    {billingOption == 1 ? "Hours" : "Coins"} Left{" "}
                    <Badge bg="badge bg-label-info" className="mb-1">
                      {totalRemainingHours || 0}
                    </Badge>
                  </p>
                </div>
                {isAnyPackage ? (
                  <Tabs
                    activeKey={packageEventKey}
                    id="uncontrolled-tab-example"
                    className="mb-4 ml-3 mt-3"
                    onSelect={(key) => setPackageEventKey(key)}
                    variant="underline"
                  >
                    <Tab eventKey="active" title="Active">
                      <>
                        <div className="" style={{ display: "grid" }}>
                          <h4 className="float-left ml-3">Monthly Packages:</h4>

                          {monthlyPackages?.length > 0 &&
                          monthlyPackages?.some((item) => item.status === 1) ? (
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Package Name</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Quantity</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Start Date</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Renew Date</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>

                                    <th>Actions</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <MemberPackageTable
                                    PackagesDetails={monthlyPackages}
                                    status={1}
                                    FetchPackageData={FetchPackageData}
                                    FetchMeetingPackageData={
                                      FetchMeetingPackageData
                                    }
                                    SpaceTypes={SpaceTypes}
                                    Editable={true}
                                    memberId={id}
                                  />
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <p className="ml-4">No Monthly Package Assigned</p>
                          )}
                        </div>
                        <div className="" style={{ display: "grid" }}>
                          <hr />

                          <h4 className="float-left ml-3">Weekly Packages:</h4>

                          {weeklyPackages?.length > 0 &&
                          weeklyPackages?.some((item) => item.status === 1) ? (
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Package Name</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Quantity</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Start Date</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Renew Date</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>

                                    <th>Actions</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <MemberPackageTable
                                    PackagesDetails={weeklyPackages}
                                    status={1}
                                    FetchPackageData={FetchPackageData}
                                    FetchMeetingPackageData={
                                      FetchMeetingPackageData
                                    }
                                    memberId={id}
                                    Editable={true}
                                  />
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <p className="ml-4">No Weekly Package Assigned</p>
                          )}
                        </div>
                        <div className="" style={{ display: "grid" }}>
                          <hr />
                          <h4 className="float-left ml-3">Daily Packages:</h4>

                          {dailyPackages?.length > 0 &&
                          dailyPackages?.some((item) => item.status === 1) ? (
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Package Name</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Quantity</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Start Date</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Renew Date</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>

                                    <th>Actions</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <MemberPackageTable
                                    PackagesDetails={dailyPackages}
                                    status={1}
                                    FetchPackageData={FetchPackageData}
                                    FetchMeetingPackageData={
                                      FetchMeetingPackageData
                                    }
                                    Editable={true}
                                    memberId={id}
                                  />
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <p className="ml-4">No Daily Package Assigned</p>
                          )}
                        </div>
                      </>
                    </Tab>
                    <Tab eventKey="pending" title="Pending">
                      <>
                        {" "}
                        <div className="" style={{ display: "grid" }}>
                          <h4 className="float-left ml-3">Monthly Packages:</h4>

                          {monthlyPackages?.length > 0 &&
                          monthlyPackages?.some((item) => item.status === 2) ? (
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Package Name</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Quantity</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Start Date</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Renew Date</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>

                                    <th>Actions</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <MemberPackageTable
                                    PackagesDetails={monthlyPackages}
                                    status={2}
                                    FetchPackageData={FetchPackageData}
                                    FetchMeetingPackageData={
                                      FetchMeetingPackageData
                                    }
                                    Editable={true}
                                    memberId={id}
                                  />
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <p className="ml-4">No Monthly Package Assigned</p>
                          )}
                        </div>
                        <div className="" style={{ display: "grid" }}>
                          <hr />

                          <h4 className="float-left ml-3">Weekly Packages:</h4>

                          {weeklyPackages?.length > 0 &&
                          weeklyPackages?.some((item) => item.status === 2) ? (
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Package Name</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Quantity</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Start Date</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Renew Date</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>

                                    <th>Actions</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <MemberPackageTable
                                    PackagesDetails={weeklyPackages}
                                    status={2}
                                    FetchPackageData={FetchPackageData}
                                    FetchMeetingPackageData={
                                      FetchMeetingPackageData
                                    }
                                    Editable={true}
                                    memberId={id}
                                  />
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <p className="ml-4">No Weekly Package Assigned</p>
                          )}
                        </div>
                        <div className="" style={{ display: "grid" }}>
                          <hr />
                          <h4 className="float-left ml-3">Daily Packages:</h4>

                          {dailyPackages?.length > 0 &&
                          dailyPackages?.some((item) => item.status === 2) ? (
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Package Name</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Quantity</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Start Date</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Renew Date</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>

                                    <th>Actions</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <MemberPackageTable
                                    PackagesDetails={dailyPackages}
                                    status={2}
                                    FetchPackageData={FetchPackageData}
                                    FetchMeetingPackageData={
                                      FetchMeetingPackageData
                                    }
                                    Editable={true}
                                    memberId={id}
                                  />
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <p className="ml-4">No Daily Package Assigned</p>
                          )}
                        </div>
                      </>
                    </Tab>
                    <Tab eventKey="history" title="History">
                      <>
                        {" "}
                        <div className="" style={{ display: "grid" }}>
                          <h4 className="float-left ml-3">Monthly Packages:</h4>

                          {monthlyPackages?.length > 0 &&
                          monthlyPackages?.some((item) => item.status === 3) ? (
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Package Name</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Quantity</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Start Date</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Renew Date</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <MemberPackageTable
                                    PackagesDetails={monthlyPackages}
                                    status={3}
                                    FetchPackageData={FetchPackageData}
                                    FetchMeetingPackageData={
                                      FetchMeetingPackageData
                                    }
                                    Editable={false}
                                  />
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <p className="ml-4">No Monthly Package Assigned</p>
                          )}
                        </div>
                        <div className="" style={{ display: "grid" }}>
                          <hr />

                          <h4 className="float-left ml-3">Weekly Packages:</h4>

                          {weeklyPackages?.length > 0 &&
                          weeklyPackages?.some((item) => item.status === 3) ? (
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Package Name</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Quantity</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Start Date</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Renew Date</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <MemberPackageTable
                                    PackagesDetails={weeklyPackages}
                                    status={3}
                                    FetchPackageData={FetchPackageData}
                                    FetchMeetingPackageData={
                                      FetchMeetingPackageData
                                    }
                                    Editable={false}
                                  />
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <p className="ml-4">No Weekly Package Assigned</p>
                          )}
                        </div>
                        <div className="" style={{ display: "grid" }}>
                          <hr />
                          <h4 className="float-left ml-3">Daily Packages:</h4>

                          {dailyPackages?.length > 0 &&
                          dailyPackages?.some((item) => item.status === 3) ? (
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Package Name</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Quantity</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Start Date</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="d-flex align-items-center sort"
                                        style={{
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>Renew Date</div>
                                        <div
                                          className="d-flex"
                                          style={{ flexDirection: "column" }}
                                        ></div>
                                      </div>
                                    </th>

                                    {/* <th>Actions</th> */}
                                  </tr>
                                </thead>

                                <tbody>
                                  <MemberPackageTable
                                    PackagesDetails={dailyPackages}
                                    status={3}
                                    FetchPackageData={FetchPackageData}
                                    FetchMeetingPackageData={
                                      FetchMeetingPackageData
                                    }
                                    Editable={false}
                                  />
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <p className="ml-4">No Daily Package Assigned</p>
                          )}
                        </div>
                      </>
                    </Tab>
                  </Tabs>
                ) : (
                  <div className="card" style={{ minHeight: "80px" }}>
                    <h6 className="ml-3 mt-4">No package record is found.</h6>
                  </div>
                )}
              </div>
            </div>

            <div
              className={` ${activeTab == 3 ? "active" : ""}`}
              id={`tab${4}`}
              onMouseEnter={() => setActiveTab(3)}
            >
              <MemberPurchase
                id={id}
                FetchPurchases={FetchPurchases?.purchases}
              />
            </div>
            
            <div
              className={` ${activeTab == 4 ? "active" : ""}`}
              id={`tab${5}`}
              onMouseEnter={() => setActiveTab(4)}
            >
              <div className="booking">
                <div className="card">
                  <div className="d-flex justify-content-between">
                    <h4 className="mt-4 ml-4">Company Bookings</h4>
                    <div className="col-xl-5 col-7 mt-3 ">
                      <input
                        type="text"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Search Bookings"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="table-responsive mt-3">
                    <table className="table mt-2 mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Meeting Room</th>
                          <th scope="col">Date</th>
                          <th scope="col">Time</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!bookingsLoading && filteredItems?.length > 0 ? (
                          filteredItems
                            // ?.slice()
                            // .reverse()
                            ?.map((item, index) => {
                              const {
                                meetingRoomTitle = item.meetingRoom?.title,
                                price = item.meetingRoom?.price,
                                bookingCapacity = item.meetingRoom?.capacity,
                                meetingRoomImagesUrls = item.meetingRoom
                                  ?.imageUrls,
                                startTime,
                                endTime,
                                cancel = item.cancel,
                              } = item;

                              return (
                                <tr key={index}>
                                  <td>
                                    <div className="media">
                                      <div className="media-aside mr-2">
                                        <img
                                          src={meetingRoomImagesUrls}
                                          alt=""
                                          className="rounded-circle"
                                          style={{
                                            width: "38px",
                                            height: "38px",
                                          }}
                                        />
                                      </div>
                                      <div className="media-body my-auto">
                                        <span className="mb-0 table-avatar">
                                          <b>{meetingRoomTitle}</b>
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    {timeStampToDate(
                                      convertUTCTimestampToLocal(startTime)
                                    )}
                                  </td>

                                  <td>
                                    {timeStampToTime(
                                      convertUTCTimestampToLocal(startTime)
                                    )}
                                    -
                                    {timeStampToTime(
                                      convertUTCTimestampToLocal(endTime)
                                    )}
                                  </td>
                                  <td>
                                    <Badge bg={!cancel ? "success" : "danger"}>
                                      {!cancel ? "Active" : "Cancelled"}
                                    </Badge>
                                  </td>
                                </tr>
                              );
                            })
                        ) : (
                          <tr>
                            <td colSpan={5} className="text-left fs-5">
                              No Bookings !
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="booking">
                <div className="card">
                  <div className="d-flex justify-content-between">
                    <h4 className="mt-4 ml-4">Employees Bookings</h4>
                    <div className="col-xl-5 col-7 mt-3 ">
                      <input
                        type="text"
                        value={search1}
                        onChange={(e) => setSearch1(e.target.value)}
                        placeholder="Search Bookings"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="table-responsive mt-3">
                    <table className="table mt-2 mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Meeting Room</th>
                          <th scope="col">Employee</th>

                          <th scope="col">Date</th>
                          <th scope="col">Time</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!employeeBookingsLoading &&
                        filteredItems1?.length > 0 ? (
                          filteredItems1
                            // ?.slice()
                            // .reverse()
                            ?.map((item, index) => {
                              const {
                                meetingRoomTitle = item.meetingRoom?.title,
                                price = item.meetingRoom?.price,
                                bookingCapacity = item.meetingRoom?.capacity,
                                meetingRoomImagesUrls = item.meetingRoom
                                  ?.imageUrls,
                                startTime,
                                employee = item?.member?.name,
                                endTime,
                                cancel = item.cancel,
                              } = item;

                              return (
                                <tr key={index}>
                                  <td>
                                    <div className="media">
                                      <div className="media-aside mr-2">
                                        <img
                                          src={meetingRoomImagesUrls}
                                          alt=""
                                          className="rounded-circle"
                                          style={{
                                            width: "38px",
                                            height: "38px",
                                          }}
                                        />
                                      </div>
                                      <div className="media-body my-auto">
                                        <span className="mb-0 table-avatar">
                                          <b>{meetingRoomTitle}</b>
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    {" "}
                                    <div className="media-body my-auto">
                                      <span className="mb-0 table-avatar">
                                        <b>{employee}</b>
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    {timeStampToDate(
                                      convertUTCTimestampToLocal(startTime)
                                    )}
                                  </td>

                                  <td>
                                    {timeStampToTime(
                                      convertUTCTimestampToLocal(startTime)
                                    )}
                                    -
                                    {timeStampToTime(
                                      convertUTCTimestampToLocal(endTime)
                                    )}
                                  </td>
                                  <td>
                                    <Badge bg={!cancel ? "success" : "danger"}>
                                      {!cancel ? "Active" : "Cancelled"}
                                    </Badge>
                                  </td>
                                </tr>
                              );
                            })
                        ) : (
                          <tr>
                            <td colSpan={5} className="text-left fs-5">
                              No Bookings !
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={` ${activeTab == 5 ? "active" : ""}`}
              id={`tab${6}`}
              onMouseMove={() => setActiveTab(5)}
            >
              <div className="invoices">
                <MemberInvoiceList id={id} />
              </div>
            </div>

            <div
              className={` ${activeTab == 6 ? "active" : ""}`}
              id={`tab${7}`}
              onMouseMove={() => setActiveTab(6)}
            >
              <div className="booking">
                <div className="card">
                  <div className="row justify-content-between align-items-center">
                    <h4 className="col-12 col-md-6 mt-4 ml-4">
                      Security Deposits
                    </h4>
                    {/* <div className="col-xl-5 col-7 mt-3 ">
                      <input
                        type="text"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Search Bookings"
                        className="form-control"
                      />
                    </div> */}
                    <div className="col-12 col-md-4 justify-content-end mt-2 text-end mr-3">
                      <AddDeposit data={companyData} isCompany={true} />
                    </div>
                  </div>
                  <div className="table-responsive mt-3">
                    <table className="table mt-2 mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Total Deposit</th>
                          <th scope="col">Issued Date</th>
                          <th scope="col">Paid Date</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {companyData &&
                        companyData?.securityDeposits?.length > 0 ? (
                          companyData?.securityDeposits
                            ?.slice()
                            ?.reverse()
                            ?.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>{item?.total}</td>
                                  <td>{formateDate(item.issued_date)}</td>

                                  <td>
                                    {item.paid_date
                                      ? formateDate(item.paid_date)
                                      : "--/--/----"}
                                  </td>
                                  <td>
                                    <Badge
                                      bg={item.paid ? "success" : "danger"}
                                    >
                                      {item.paid ? "Paid" : "Not Paid"}
                                    </Badge>
                                  </td>
                                </tr>
                              );
                            })
                        ) : (
                          <tr>
                            <td colSpan={5} className="text-left fs-5">
                              No Deposits Found !
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={` ${activeTab == 7 ? "active" : ""}`}
              id={`tab${8}`}
              onMouseEnter={() => setActiveTab(7)}
            >
              <div className="booking">

                <MemberAccessGroup id={id} />
              </div>
            </div>

            <div className="spacing" style={{ height: "70vh" }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailedCompanyPage;
const leftDivStyle = {
  overflowY: "scroll",
  maxHeight: "calc(100vh - 70px)",
  scrollBehaviour: "smooth",
  marginBottom: "0px",
};

const rightDivStyle = {
  overflowY: "scroll",
  maxHeight: "calc(100vh - 175px)",
};
