import axios from "axios";
import React, { useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { ReactComponent as EditIcon } from "../../Assets/EditIcon.svg";
import { requiredAlert } from "../../utils/Alerts";
import { colorStyles } from "../../utils/constant";
import Select from "react-select";
import {
  useGetRelatedMeetingRoomsQuery,
  useUpdateMeetingRoomPackageMutation,
} from "../../Slices/spaceApi";
import { toast, Toaster } from "react-hot-toast";
import { useEffect } from "react";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";
import { CancelIcon } from "../../Assets/InvoiceSvgs/Icons";
import { useGlobalContext } from "../../Context/GlobalContext";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import PackagePrice from "./PackagePrice";

const UpdateMeetingPackage = ({ items, index, FetchSpaceDurations }) => {
  const { packagesPermissions, billingOption, currency, isOverAllTax } =
    useGlobalContext();
  const {
    name,
    hours,
    serviceCharges,
    rentalFee,
    expiryDuration,
    inclusiveTax,
    id,
    taxRateObj,
    meetingRoomAllowedHours,
  } = items[index];
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const meetingRoomData = meetingRoomAllowedHours;
  console.log("Tax rate obj is ", taxRateObj);
  const [updateMeetingRoomPackage, resultStats] =
    useUpdateMeetingRoomPackageMutation();
  const [show, setShow] = useState(false);
  const { isLoading: relatedLoading, data: FetchMeetingRoom } =
    useGetRelatedMeetingRoomsQuery({ id: spaceId || currentSpaceId });

  const handleShow = () => setShow(true);
  const [allDone, setAllDone] = useState(true);
  const [errorDurations, setErrorDurations] = useState(false);
  const [apiSpinner, setApiSpinner] = useState(false);
  const [meetingRoomSelect, setMeetingRoomSelect] = useState(false);

  const [rows, setRows] = useState([
    {
      selectedLocation: null,
      selectedMeetingRooms: [],
      hours: 0,
    },
  ]);
  const uniqueLocations = Array.from(
    new Set(FetchMeetingRoom?.map((room) => room.space.name))
  );
  const [focused, setFocused] = useState({
    name: false,
    // hours: false,
    serviceCharges: false,
    rentalFee: false,
    expiry: false,
  });

  const [data, setData] = useState({
    name: name,
    // hours: hours,
    // price: price,
    serviceCharges: serviceCharges,
    rentalFee: rentalFee,
    inclusiveTax: inclusiveTax,

    expiryDuration: FetchSpaceDurations?.find(
      (item) => item.spaceDuration == expiryDuration
    )?.id,
  });
  const handleApprovePublicBooking = () => {
    setMeetingRoomSelect((prevValue) => {
      const newValue = !prevValue;
      return newValue;
    });
    // setMeetingRoomSelect(true);
  };
  useEffect(() => {
    if (FetchMeetingRoom?.length > 0 && meetingRoomData?.length > 0) {
      setRows(
        meetingRoomData?.map((item) => {
          const selectedMeetingRooms = item.meetingRoom?.map((roomId) =>
            FetchMeetingRoom?.find((room) => room.id === roomId)
          );

          return {
            selectedLocation: null,
            selectedMeetingRooms,
            hours: item.hours,
          };
        })
      );
    } else {
      return;
    }
  }, [FetchMeetingRoom, show]);

  const handleAddRow = () => {
    const newRow = {
      selectedLocation: null,
      selectedMeetingRooms: [],
      hours: 0,
    };
    setRows([...rows, newRow]);
  };

  const handleRemoveRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };

  const handleLocationChange = (event, rowIndex) => {
    const selectedLocation = event.target.value;
    const updatedRows = [...rows];
    updatedRows[rowIndex].selectedLocation = selectedLocation;
    setRows(updatedRows);
  };
  const handleInclusiveTax = (event) => {
    setData({
      ...data,
      inclusiveTax: event.target.checked,
    });
  };
  const handleMeetingRoomChange = (selectedOptions, rowIndex) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex].selectedMeetingRooms = selectedOptions;
    setRows(updatedRows);
  };

  const handleHoursChange = (e, rowIndex) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex].hours = e.target.value;
    setRows(updatedRows);
  };
  const handleUpdateMeeting = (item) => {
    setData({
      name: item.name,
      // hours: item.hours,
      // price: item.price,
      serviceCharges: item.serviceCharges,
      rentalFee: item.rentalFee,
      inclusiveTax: item.inclusiveTax,

      expiryDuration: FetchSpaceDurations?.find(
        (find) => find.spaceDuration == item.expiryDuration
      )?.id,
    });
  };

  useEffect(() => {
    handleUpdateMeeting({
      name,
      serviceCharges,
      rentalFee,
      expiryDuration,
      inclusiveTax,
      id,
    });
  }, [name, serviceCharges, rentalFee, expiryDuration, inclusiveTax, id]);

  const handle = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleDuration = (e) => {
    setData({ ...data, expiryDuration: e.id });
  };

  const handleClose = () => {
    setShow(false);
    // setData({
    //   ...data,
    //   name: name,
    //   hours: hours,
    //   price: price,
    //   expiryDuration: FetchSpaceDurations?.find(
    //     (item) => item.name == expiryDuration
    //   )?.id,
    // });
  };

  const postData = (e) => {
    e.preventDefault();
    const { name, serviceCharges, rentalFee, expiryDuration, inclusiveTax } =
      data;

    const meetingRoomAllowedHours = rows?.map((row) => ({
      meetingRoom: row.selectedMeetingRooms?.map((item) => item.id) || [],
      hours: row.hours || 0,
    }));
    let setHours = +hours;

    if (allDone) {
      return;
    }

    let updateData = {
      name,
      // hours: setHours,
      // price: +price,
      serviceCharges: +serviceCharges,
      rentalFee: +rentalFee,
      expiryDuration: +expiryDuration,
      inclusiveTax: inclusiveTax,
      meetingRoomAllowedHours: meetingRoomAllowedHours,
    };
    setApiSpinner(true);
    updateMeetingRoomPackage({
      id,
      data: updateData,
    });
  };

  useEffect(() => {
    if (
      // !data.hours == "" &&
      // !data.price == "" &&
      !data.serviceCharges == "" &&
      // !data.rentalFee == "" &&
      data.name.match("[A-Za-z ][A-Za-z0-9!@#$%^&* ]*$") &&
      !data.expiryDuration == ""
    ) {
      setAllDone(false);
    } else {
      setAllDone(true);
    }
  }, [data]);

  useEffect(() => {
    if (resultStats?.isError) {
      setApiSpinner(false);

      requiredAlert(resultStats?.error?.data?.error);
    }

    if (resultStats?.isSuccess) {
      setTimeout(() => {
        handleClose();
      }, 1500);
      setApiSpinner(false);

      toast.success("Meeting Room Package Updated Successfully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  }, [resultStats]);

  const handleFocus = (e) => {
    setFocused({ ...focused, [e.target.name]: true });
  };

  const onBlurDuration = () => {
    if (data?.expiryDuration === "") {
      setErrorDurations(true);
    }
  };

  const onFocusDuration = (e) => {
    if (e.target.value === data.expiryDuration) {
      setErrorDurations(false);
    }
  };

  return (
    <>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip">
            {packagesPermissions[0]?.update ? (
              "Update Package"
            ) : (
              <span>
                Update <Lock />
              </span>
            )}
          </Tooltip>
        }
      >
        <Button
          variant="white"
          className="mx-2 p-0"
          onClick={packagesPermissions[0]?.update ? handleShow : null}
        >
          <EditIcon />
        </Button>
      </OverlayTrigger>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Meeting Package</Modal.Title>
        </Modal.Header>
        {/* <small className="fw-bold ml-3 mt-2">
          Make Meeting Room's Package for Your Space
        </small> */}
        <form>
          <Modal.Body>
            <div className="form-group">
              <label htmlFor="name">Package Name</label>
              <input
                type="text"
                name="name"
                id="name"
                className="form-control"
                value={data.name}
                onChange={handle}
                onBlur={handleFocus}
                required
                pattern={"^[A-Za-z ][A-Za-z0-9!@#$%^&* ]*$"}
                focused={focused?.name.toString()}
              />
              <span className="error">{"Enter Name"}</span>
            </div>

            <div className="form-group">
              <label htmlFor="serviceCharges">Service Charges</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">{currency}</span>
                </div>
                <input
                  type="text"
                  name="serviceCharges"
                  id="serviceCharges"
                  className="form-control"
                  placeholder="Enter Service Charges"
                  value={data.serviceCharges}
                  onChange={handle}
                  onBlur={handleFocus}
                  focused={focused?.serviceCharges?.toString()}
                  required
                />
              </div>
              <span className="error">{"Enter Service Charges"}</span>
            </div>
            {!isOverAllTax && (
              <div className="form-group">
                <label htmlFor="rentalFee">Rental Fee</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">{currency}</span>
                  </div>
                  <input
                    type="text"
                    name="rentalFee"
                    id="rentalFee"
                    className="form-control"
                    placeholder="Enter Rental Fee"
                    value={data?.rentalFee}
                    onChange={handle}
                    onBlur={handleFocus}
                    focused={focused?.rentalFee?.toString()}
                    required
                  />
                </div>
                <span className="error">{"Enter Rental Fee"}</span>
              </div>
            )}

            <div className="custom-formcheck form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="inclusiveTax"
                checked={data?.inclusiveTax}
                onChange={(e) => handleInclusiveTax(e)}
              />
              <label className="form-check-label" htmlFor="inclusiveTax">
                Check if prices include tax
              </label>
            </div>

            {/* <div className="form-check">
              <label className="form-check-label" htmlFor="inclusiveTax">
                Is this Price Includes Tax!
              </label>
              <input
                type="checkbox"
                className="form-check-input ml-2"
                id="inclusiveTax"
                checked={data?.inclusiveTax}
                onChange={(e) => handleInclusiveTax(e)}
              />
            </div> */}

            <PackagePrice
              priceWithTax={data?.inclusiveTax}
              priceService={data?.serviceCharges}
              priceRental={data?.rentalFee}
              taxRateObj={taxRateObj}
            />

            <div className="form-group">
              <label htmlFor="expiryDuration">Expiry Duration</label>
              <Select
                options={FetchSpaceDurations}
                getOptionLabel={(option) => option.spaceDuration}
                getOptionValue={(option) => option.id}
                styles={colorStyles}
                value={FetchSpaceDurations?.find(
                  (obj) => obj.id === data.expiryDuration
                )}
                onChange={handleDuration}
                onBlur={onBlurDuration}
                id="expiryDuration"
                onFocus={onFocusDuration}
              />
              {errorDurations && (
                <span className="e">Select The Durations</span>
              )}
            </div>

            <div className="d-flex mt-4" style={{ cursor: "pointer" }}>
              <p htmlFor="" className="">
                Add Meeting Rooms
              </p>
              <Form>
                <Form.Check
                  type="switch"
                  id="custom-switch2"
                  label=""
                  className="ml-2"
                  value={meetingRoomSelect}
                  checked={meetingRoomSelect}
                  onChange={handleApprovePublicBooking}
                />
              </Form>
            </div>
            {meetingRoomSelect && (
              <>
                <div className="form-group">
                  <label htmlFor="package-facilities" className="mb-1">
                    Meeting Room (Select Multiple)*
                  </label>
                  {FetchMeetingRoom?.length > 0 &&
                    rows?.map((row, index) => (
                      <div key={index} className="repeater-wrapper my-2">
                        <div className="">
                          <p className="card-text col-title mb-md-50 mb-0 ml-3">
                            Select Location
                          </p>

                          <select
                            onChange={(e) => handleLocationChange(e, index)}
                            value={row.selectedLocation}
                            className="form-control col-10 ml-3"
                          >
                            <option value="">All Locations</option>
                            {uniqueLocations?.map((location) => (
                              <option key={location} value={location}>
                                {location}
                              </option>
                            ))}
                          </select>
                          <div className="col-12 d-flex product-details-border position-relative pe-0 pl-3 fs-6">
                            <div className="row w-100 pe-lg-0 pe-3">
                              <div className="col-12 mb-lg-0 mb-2 mt-lg-0">
                                <p className="card-text col-title mb-md-50 mb-0">
                                  Select allowed meeting rooms
                                </p>
                                <Select
                                  options={
                                    !row.selectedLocation
                                      ? FetchMeetingRoom
                                      : FetchMeetingRoom?.filter(
                                          (room) =>
                                            room.space.name ===
                                            row.selectedLocation
                                        )
                                  }
                                  getOptionLabel={(option) => option.title}
                                  getOptionValue={(option) => option.id}
                                  closeMenuOnSelect={false}
                                  styles={colorStyles}
                                  value={row.selectedMeetingRooms}
                                  onChange={(selectedOptions) =>
                                    handleMeetingRoomChange(
                                      selectedOptions,
                                      index
                                    )
                                  }
                                  isMulti
                                  id={`space-package-${index}`}
                                />
                              </div>

                              <div className="col-12 my-lg-0 my-2">
                                {billingOption == 1 ? (
                                  <p className="card-text col-title mb-0">
                                    Add hours for this package
                                  </p>
                                ) : billingOption == 2 ? (
                                  <p className="card-text col-title mb-0">
                                    Add coins for this package
                                  </p>
                                ) : (
                                  <p className="card-text col-title mb-0 text-danger">
                                    Set Biiling Option in Settings
                                  </p>
                                )}

                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="1"
                                  min={1}
                                  name="hours"
                                  value={row.hours}
                                  onChange={(e) => handleHoursChange(e, index)}
                                />
                              </div>
                            </div>
                            {/* <div className="d-flex flex-column align-items-center justify-content-between  invoice-product-actions py-4 px-2">
                          <button
                            className="bg-white"
                            onClick={() => handleRemoveRow(index)}
                          >
                            <CancelIcon />
                          </button>
                        </div> */}
                          </div>
                        </div>
                        <hr />
                      </div>
                    ))}
                </div>
                {FetchMeetingRoom?.length <= 0 && (
                  <p>No Package is Available</p>
                )}
              </>
            )}

            <div className="mt-4">
              {" "}
              <Button
                variant="primary"
                disabled={apiSpinner}
                onClick={postData}
              >
                Save Changes{" "}
                {apiSpinner ? (
                  <div
                    className="spinner-border text-primary"
                    role="status"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "5px",
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </Button>
              <button
                className="btn btn-secondary ml-3"
                disabled={apiSpinner}
                onClick={(e) => {
                  e.preventDefault();
                  handleClose();
                }}
              >
                Cancel
              </button>
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
           
          </Modal.Footer> */}
        </form>
      </Modal>
    </>
  );
};

export default UpdateMeetingPackage;
