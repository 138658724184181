import React from "react";
import Logo1 from "../../Assets/dummyImages/Logo1.png";
import { useEffect } from "react";
import { useState } from "react";

const LogoDetails = ({ city, name, number, address, imageUrls }) => {
  return (
    <>
      <div className="mb-5 mb-lg-0 logo-details">
        <div>
          <span className="logo-img me-2">
            <img
              id="logo-img"
              src={imageUrls[0] || Logo1}
              style={{
                width: "50px",
                borderRadius: "50%",
                height: "50px",
                objectFit: "contain",
              }}
              onError={(e) => {
                console.error("Error loading image:", e);
                e.target.src = Logo1;
              }}
              // onLoad={handleImageLoad}
              alt="logo-img"
            />
          </span>

          <span className="fw-bold fs-4">{name}</span>
        </div>
        <p className="card-text my-1">{address}</p>
        <p className="card-text m-0">{number}</p>
      </div>
    </>
  );
};

export default LogoDetails;
